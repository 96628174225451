.bg-primary {
  background-color: #595959!important;
}
.btn-primary{
      background-color: #595959!important;
      border-color:black;
}

@media print {

  app-root app-print-layout {
    display: block;
  }

  .container {
    min-width: 0px;
    max-width: 0px;
  }

  #footer {
    display: none;
  }

  #paddingp {
    display: none;
  }

  .page{
  padding:0mm!important;

}


  .margin-image{
    margin-left:692px!important;
  }

  #navbarprint {
    display:none;
  }
  #printbuttonp{
display:none;
  }
  body {
    margin-bottom:0;
  }

}


.modal-dialog {
  max-width: 800px!important;
}
